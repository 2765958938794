<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			row: false,
			data: false,
		}
	},
	async mounted() {
		await this.ready();
		this.getDetail()
		setTimeout(()=>{ $("a[href$='event']").closest("li").addClass("active") }, 300)
	},
	methods: {
		getDetail(){
			this.loadingOverlay = true
			this.row = false
			Gen.apirest("/event-detail/"+this.$route.params.slug, {}, (err, resp)=>{
				this.loadingOverlay = false
				if (resp.code == 404) return this.$router.push({name:'404'})
				this.row = resp.row
				this.getAnother()
			})
		},
		getAnother(){
			Gen.apirest("/event-list", {notid:this.row.me_id,limit:4}, (err, resp)=>{
				this.data = resp.data
			})
		}
	},
	watch:{
		$route(){
			this.getDetail()
			setTimeout(()=>{ $("a[href$='event']").closest("li").addClass("active") }, 300)
		}
	}
}
</script>
<template>
	<section id="content" v-if="row">
		<section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'Event'}">Event</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{row.me_title}}</li>
				</ol>
			</div>
		</section>
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<div class="blog-post">
						<div class="row justify-content-center">
							<div class="col-md-8">
								<div class="post-body">
									<div class="pb-head pb-0">
										<div class="form-row">
											<h2 class="ph-title mb-2">{{row.me_title}}</h2>
										</div>
									</div>
									<div class="pb-main">
										<img :src="uploader(row.me_image)" :alt="row.me_title">
										<div v-html="row.me_desc"></div>
									</div>
								</div>
							</div>
							<div class="col-md-4" v-if="data&&data.total">
								<div class="related-article mb-0">
									<h3>Event Lainnya</h3>
									<router-link :to="{name:'DetailEvent',params:{slug:v.me_slug}}" class="ra-item" v-for="(v,k) in data.data" :key="k">
										<div class="row">
											<div class="col-4">
												<img :src="uploader(v.me_image)" :alt="v.me_title">
											</div>
											<div class="col-8">
												<div class="ra-desc">
													<h4>{{v.me_title}}</h4>
												</div>
												<!-- <div class="writer-block">
													<span><i class="icon-calendar21"></i> {{v.me_publish_date.dates('format')}}</span>
												</div> -->
											</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</section>
</template>